/* config variables */
@import "variables";

/* modules */
@import "custom/modules/mixins";
@import "custom/modules/select";
@import "custom/modules/buttons";
@import "custom/modules/custom_effects";

/* bootstrap */
@import "bootstrap/bootstrap";
@import "bootstrap/overrides";

/* vendor */
@import "vendor/animate";
@import "vendor/magnific.popup";
@import "vendor/pikaday";
@import "vendor/simpletextrotator";

/* icons */
@import "vendor/ionicons";
@import "vendor/font-awesome";
@import "vendor/et-line-icons";
@import "vendor/entypo";

/* snippets */
@import "custom/snippets/slider_effects";
@import "custom/snippets/slider_element";

/* layout */
@import "custom/layout";

/* pages */
@import "custom/pages/intro";
@import "custom/pages/index";
@import "custom/pages/index_mobile";
@import "custom/pages/index_mobile_2";
@import "custom/pages/index_mobile_3";
@import "custom/pages/index_mobile_4";
@import "custom/pages/index_slider";
@import "custom/pages/index_features";
@import "custom/pages/index_photography";
@import "custom/pages/index_video";
@import "custom/pages/index_app";
@import "custom/pages/index_dark";
@import "custom/pages/index_restaurant";

/* general pages */
@import "custom/pages/pricing_charts";
@import "custom/pages/pricing";
@import "custom/pages/coming_soon";
@import "custom/pages/timeline";
@import "custom/pages/api_docs";
@import "custom/pages/support";
@import "custom/pages/support_topic";
@import "custom/pages/sign_up";
@import "custom/pages/sign_in";
@import "custom/pages/careers";
@import "custom/pages/career_post";
@import "custom/pages/customers";
@import "custom/pages/customer_story";
@import "custom/pages/theme_docs";

/* blogs */
@import "custom/pages/blog";
@import "custom/pages/blog_grid";
@import "custom/pages/blog_masonry";
@import "custom/pages/blog_timeline";
@import "custom/pages/blog_sidebar";
@import "custom/pages/blog_3_cols";
@import "custom/pages/blog_post";
@import "custom/pages/blog_post_alt";
@import "custom/pages/blog_post_3";

/* agency */
@import "custom/pages/agency";
@import "custom/pages/agency_about";
@import "custom/pages/agency_contact";
@import "custom/pages/agency_project";
@import "custom/pages/agency_portfolio";
@import "custom/pages/agency_start_project";
