.support-hero {
  padding-top: 130px;
  position: relative;
  background: #f5f7f9;
  border-bottom: 1px solid #DEE4EA;

  @media(max-width: 767px) {
    padding-top: 100px;
  }

  .container {
    position: relative;
    z-index: 10;
  }

  form {
    position: relative;

    input[type="text"] {
      border: 1px solid #D4DAE0;
      width: 100%;
      padding: 16px 80px;
      border-radius: 50px;
      outline: none;
      font-size: 18px;

      @include transition(all .2s);

      @include placeholder {
        color: #A1A1A1;
      }

      @media(max-width: 767px) {
        font-size: 16px;
        padding: 15px 70px;
      }

      &:focus {
        border-color: #A4B8CB;
      }
    }

    .fa-search {
      position: absolute;
      font-size: 22px;
      top: 19px;
      left: 30px;
      color: #9cabcc;

      @media(max-width: 767px) {
        top: 16px;
      }
    }
  }

  .topics {
    margin: 30px 0;

    .topic {
      display: inline-block;
      float: left;
      text-decoration: none;
      width: 25%;
      text-align: center;
      padding: 50px 0;
      border: 1px solid #f5f7f9;
      border-radius: 14px;

      @include transition(all .2s);

      @media(max-width: 767px) {
        width: 50%;
        padding: 35px 0;
      }

      &:hover {
        border-color: #E3E7EC;
        background: #EEF2F5;
        color: #459CE7;
      }

      i {
        color: #407eb3;
        font-size: 52px;
        vertical-align: middle;

        @media(max-width: 991px) {
          font-size: 40px;
        }
      }

      span {
        display: inline-block;
        margin-left: 15px;
        position: relative;
        top: 5px;
        color: #3d3e46;
        text-shadow: 1px 1px rgba(255, 255, 255, 0.85);
      }
    }
  }
}

.support-faq {
  margin-top: 50px;

  @media(max-width: 767px) {
    text-align: center;
  }

  .col-sm-4 {
    @media(max-width: 767px) {
      margin-bottom: 30px;
    }
  }

  h3 {
    margin-bottom: 30px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 1px;
  }

  h4 {
    margin-bottom: 25px;
    font-weight: 500;
    font-size: 15px;
  }

  ul {
    padding-left: 0;
    list-style-type: circle;
    color: #9ab0ce;

    @media(max-width: 767px) {
      list-style-type: none;
    }
  }
  
  a {
    display: block;
    margin-bottom: 13px;
    text-decoration: none;
    
    @include transition(all .2s);
  }
}

.support-contact {
  margin-top: 60px;
  margin-bottom: 180px;

  @media(max-width: 767px) {
    text-align: center;
    margin-bottom: 100px;
  }

  h3 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 1px;
    margin-bottom: 50px;
    border-top: 1px solid #eee;
    padding-top: 60px;
  }

  .col-sm-6 {
    &:first-child {
      @media(min-width: 768px) {
        border-right: 1px solid #eee;
      }
    }

    &:last-child {
      @media(min-width: 768px) {
        padding-left: 70px;
      }

      @media(max-width: 767px) {
        margin-top: 40px;
      }
    }
  }

  img {
    margin-bottom: 20px;

    &.devices {
      max-width: 190px;
    }

    &.messages {
      max-width: 106px;
    }
  }

  h4 {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 20px;
  }

  p {
    font-size: 15px;
    color: #454545;
    width: 80%;
    line-height: 26px;

    @media(max-width: 767px) {
      width: auto;
    }
  }
}
